<template>
	<div>
		<Button color="secondary" xSmall tile flat :click="() => dialog = true" :toolTip="$t('Editor.add_token')" iconVal="mdi-plus"/>

		<Dialog v-model="dialog" :title="$t('Editor.token_title')" @close="dialog = false" :width="500">
			<template v-slot:content>
				<v-row
						v-if="token && token['account'] && token['field'] && token['account'].length > 0 && Object.keys(token['field']).length > 0"
						class="mx-0 my-2 sub-title-2"
				>
					<template v-for="(items, key) in token">
						<v-col class="pa-0" :key="key">
							<v-row class="font-weight-bold mx-0 pb-4">
								<v-col class="py-0">
									{{ key === 'account' ? $tc('global.name.account', token['account'].length  === 1 ? 1 : 2) :  $tc('global.name.fields', Object.keys(token['field']).length === 1 ? 1 : 2) }}
								</v-col>
							</v-row>
							
							<v-row style="max-height: 300px; overflow-y: auto;" class="mx-0">
								<v-col class="pa-0">
									<v-list class="transparent" dense>
										<v-list-item-group
												v-model="values[key]"
										>
											<v-list-item
													v-if="key ==='account'"
													class="px-0"
													:value="0"
													active-class="black2white--text"
											>
												<v-list-item-content >
													<v-list-item-content class="px-4 caption-1" >{{ $tc("global.name.current_account", 1) }}</v-list-item-content>
												</v-list-item-content>
											</v-list-item>
											<template v-for="(item, i) in items">
												<v-list-item
														class="px-0"
														:key="key === 'account' ? `item-${i}` : i"
														:value="key === 'account' ? item.id : item"
														active-class="black2white--text"
												>
													<template v-slot:default>
														<v-list-item-content class="py-0">
															<v-list-item-content class="px-4 caption-1">{{ key === 'account' ? item.display_name : item.label}}</v-list-item-content>
														</v-list-item-content>
													</template>
												</v-list-item>
											</template>
										</v-list-item-group>
									</v-list>
								</v-col>
							</v-row>
						</v-col>
						<v-divider vertical v-if="key === 'account'"/>
					</template>
				</v-row>
				<v-row v-else >
					<v-col class="text-center">
						<v-progress-circular
							indeterminate
							color="primary"
						/>
					</v-col>
				</v-row>
				<v-row class="mx-0" >
					<v-col>
						<v-btn block class="rounded-lg font-weight-bold" color="cell3" @click="dialog = false">{{ $t('global.action.cancel') }}</v-btn>
					</v-col>
					<v-col>
						<v-btn block class="rounded-lg font-weight-bold" :loading="loading" color="primary" @click="addCustomField()">{{ $t('global.action.validate')  }}</v-btn>
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "TokenSelect",
		props: ['value'],
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		data() {
			return {
				dialog: false,
				loading:false,
				token: {},
				values: {
					account: 0,
					field:''
				}
			}
		},
		methods: {
			addCustomField() {
				this.loading = true

				let label = ""
				let keys = Object.keys(this.token['field'])

				for (let i = 0; i < keys.length; i++) {
					if (this.token['field'][keys[i]].label === this.values.field.label)
						label = keys[i]
				}

				this.$emit('token','{{Account.' + (this.values.account === 0 ? '#' : this.values.account) + '.' + label + '}}')
				this.loading = false
				this.dialog = false
			},
			loadFields () {
				this.$wsc.getOptions(this.$route.params, {}, 'account', success => {
					this.$set(this.token, 'field', success.fields)
				}, fail => {  })
			},
			loadData () {
				this.$wsc.getList('account', {}, success => {
					this.$set(this.token, 'account', success)
				}, fail => {  })
			}
		},
		created(){
			this.loadFields()
			this.loadData()
		}
	}
</script>

<style scoped>

</style>
